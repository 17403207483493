import { CListGroup, CListGroupItem } from "@coreui/react";
import React from "react";

const HelmInstructions = ({ instructions, requestFromDCTable }) => {
  return (
    <div className={!requestFromDCTable ? "card-footer" : ""}>
      {!requestFromDCTable &&
        (instructions.datacenter ? (
          <h6 className="text-success">Datacenter created Successfully</h6>
        ) : (
          <h6 className="text-danger">
            Error creating datacenter. Please try again later or contact the
            klusternetes team
          </h6>
        ))}
      {instructions.datacenter && (
        <>
          {!requestFromDCTable && (
            <h5 className="py-2">Instructions for Helm Install</h5>
          )}
          <CListGroup className="kntsCLICommands">
            <CListGroupItem>
              <div className="d-flex w-100 justify-content-between">
                <h6 className="mb-1">
                  # Reference the Datacenter operator repo
                </h6>
                <small>Make sure helm is pre-installed in your system</small>
              </div>
              <p className="mb-1">
                <i className="fa fa-chevron-right" />
                {`helm repo add klusternetes `}
                <span className="text-info">{`https://charts-finiykodma-uw.a.run.app/`}</span>
              </p>
            </CListGroupItem>
            <CListGroupItem>
              <div className="d-flex w-100 justify-content-between">
                <h6 className="mb-1"># Values.yaml for the new datacenter</h6>
                <small>
                  {`Copy this into your local system to edit (As necessary)`}
                  <br />
                  {`Refer to datacenters view for details`}
                </small>
              </div>
              <p className="mb-1">
                <i className="fa fa-chevron-right" />
                {`cat values.yaml`}
              </p>
              <div style={{ whiteSpace: "pre-wrap" }} className="text-success">
                {`
credentials:
  username: ${instructions.datacenter.username || "<datacenter_username>"}
  password: ${instructions.datacenter.password || "<datacenter_password>"}

cluster:
  id: ${instructions.datacenter.name || "<datacenter_name>"}
`}
              </div>
            </CListGroupItem>
            <CListGroupItem>
              <div className="d-flex w-100 justify-content-between">
                <h6 className="mb-1">
                  # Setup the datacenter with updated values.yaml
                </h6>
              </div>
              <p className="mb-1">
                <i className="fa fa-chevron-right" />
                {`helm upgrade --install knts-operator klusternetes/knts-operator -n knts-operator --create-namespace -f values.yaml`}
              </p>
            </CListGroupItem>
          </CListGroup>
        </>
      )}
    </div>
  );
};

export default HelmInstructions;
